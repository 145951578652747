<template>
  <div
    :style="background ? bgStyle : ''"
    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full"
  ></div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: "",
    },
  },
  computed: {
    bgStyle() {
      return `background-image: url('${this.background}'); `;
    },
  },
};
</script>
